/* Astroclock.module.css */
.astroclock {
  position: relative;
  width: 700px;
  height: 700px;
  border-radius: 50%;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%) rotateY(180deg);
  /* overflow: hidden; */
  /* transition: all ease 300ms; */
}

.astroclockDisabled {
  position: relative;
  width: 700px;
  height: 700px;
  border-radius: 50%;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%) rotateY(180deg);
  /* overflow: hidden; */
  /* transition: all ease 300ms; */
  /* filter: contrast(0.5); */
  pointer-events: none;
  cursor: not-allowed;
}

.astroclockAvailable {
  position: relative;
  width: 700px;
  height: 700px;
  border-radius: 50%;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%) rotateY(180deg);
  /* overflow: hidden; */
  /* transition: all ease 300ms; */
  /* filter: contrast(0.5); */
  pointer-events: none;
  cursor: not-allowed;
}

.clockFace {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Remove the smooth transition for a ticking effect */
  transition: none;
  background-image: url("/public/assets/Clock/Clock.png");
  background-size: contain;
  position: relative;
  /* transition: all ease 300ms; */
}

.clockFaceDisabled {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Remove the smooth transition for a ticking effect */
  transition: none;
  background-image: url("/public/assets/Clock/Clock2.png");
  background-size: contain;
  position: relative;
  /* transition: all ease 300ms; */
}

.clockFaceAvailable {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Remove the smooth transition for a ticking effect */
  transition: none;
  background-image: url("/public/assets/Clock/Clock.png");
  background-size: contain;
  position: relative;
  /* transition: all ease 300ms; */
}

.indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) rotate(180deg);
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 300ms;
}

.image {
  position: absolute;
  width: 45px;
  height: 45px;
  transform-origin: center;
  padding-top: 15px;
  transition: filter 0.2s;
}

.needle {
  position: absolute;
  z-index: 2;
  transform: translate(-50%, 0);
  left: 50%;
  top: 0;
  /* opacity: 0.5; */
}

.active {
  filter: brightness(1.5);
}

.redDot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
}
