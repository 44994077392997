.libraryAreaContainer {
  height: 80%;
}

.libraryAreaWrapper {
  position: relative;
  height: 100%;
}

.buttonsWrapper {
  position: absolute;
  display: flex;
  top: 0;
  transform: translateY(-38%);
  left: 10px;
  width: 100%;
}

.buttonsWrapper button {
  position: relative;
  background-image: url("/public/assets/equipment_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-family: "REDENSEK", sans-serif;
  color: #fff;
  width: 100%;
  max-width: 180px;
  height: 34px;
  font-size: 20px;
  cursor: pointer;
  text-transform: uppercase;
}

.buttonsWrapper button span {
  position: relative;
}

.disable::after {
  content: "";
  opacity: 0.6;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.newAvailable::before {
  content: "";
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fda802;
  filter: brightness(110%);
  animation: blinker 1.7s cubic-bezier(0.5, 0, 1, 1) infinite;
}

.libraryArea {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("/public/assets/astro\ clock\ bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.libraryArea h1 {
  width: 90%;
  color: white;
}

.slot {
  width: 60px;
  height: 60px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 14px;
  margin: 10px 0;
}
