.birthdayContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-family: "Press Start 2P", cursive;
  color: #ffffff;
  background-image: url("/public/assets/Birthday/birthdayBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.birthdayContainer p {
  margin-top: 50px;
  margin-bottom: 0;
  font-size: 20px;
  font-family: "REDENSEK", sans-serif;

}
