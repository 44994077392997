.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

p {
  font-size: 1.5em;
  font-weight: 500;
  font-family: "REDENSEK", sans-serif;
}

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 1.75em;
  height: 1em;
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: relative;
  cursor: pointer;
  background-color: #B0B0B0;
  border: 1px solid #B0B0B0;
  transition: background-color 0.4s;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sliderHandle {
  background-color: #fff;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  transition: transform 0.25s ease-in-out;
}

.sliderIcon {
  opacity: 0;
  height: 6px;
  width: 6px;
  stroke-width: 4;
  stroke: #222222;
  transition: opacity 0.15s ease-in-out;
  position: absolute;
  top: 50%;
  right: 60%;
  transform: translateY(-50%);
}

/* Checked state */
.checked {
  background-color: #222222;
}

.checkedHandle {
  transform: translateX(0.75em);
}

.iconVisible {
  opacity: 1;
  right: 20%;
}
