.dialogueContainer {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: #000;
}

.upperPart {
  /* flex: 2; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 65%;
  background-image: url("/public/assets/main scene/main\ scene\ bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.backBtn {
  position: absolute;
  top: 25%;
  right: 0%;
  color: #fff;
  border: none;
  font-family: "REDENSEK", sans-serif;
  /* font-size: 28px; */
  cursor: pointer;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.background {
  position: relative;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.character {
  /* width: 45%; */
  height: auto;
}

.character img {
  width: 100%;
  height: auto;
}

.lowerPart {
  /* flex: 1; */
  width: 100%;
  height: 47.5%;
  /* margin-top: -20%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("/public/assets/Story/Ui/bubbles/bubble\ annie.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
}

.lowerPart.mayBubble {
  background-image: url("/public/assets/Story/Ui/bubbles/bubble\ may.png");
}

.dialogueBox {
  width: 75%;
  /* height: 100%0; */
  color: #fff;
  margin-top: 5%;
  /* padding: 10px; */
  /* margin-bottom: 10px; */
}

.dialogueText {
  font-size: 32px;
  font-family: "Press Start 2P", cursive;
  user-select: none;
}

.speaker{
  position: absolute;
  left: 7.5%;
  top: 5%;
  font-size: 48px;
  color: rgb(213, 212, 212);
}

.speakerOffset{
  left: 8.75%;
}

.nextBtn {
  position: absolute;
  color: #fff;
  border: none;
  font-family: "REDENSEK", sans-serif;
  cursor: pointer;
  font-size: 28px;
  flex: 2;
  bottom: 5%;
  right: 2.5%;
  opacity: 1;
}

.prevBtn {
  position: absolute;
  color: #fff;
  border: none;
  font-family: "REDENSEK", sans-serif;
  cursor: pointer;
  font-size: 28px;
  flex: 2;
  bottom: 5%;
  left: 2.5%;
  opacity: 1;
}

.progressBarContainer {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 75%;
  height: 100%;
}
.progressBar {
  height: 5px;
  background: white;
  position: relative;
  transition: all;
  border-radius: 25px;
  z-index: 2;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.reaction{
  z-index: 999;
  position: absolute;
}

.annieReaction {
  top: 25%;
  left: 70%;
}

.mayReaction {
  top: 25%;
  left: 10%;
}

.reaction img {
  width: 75%;
}