.nameContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
  font-family: "Press Start 2P", cursive;
  color: #ffffff;
  background-image: url("/public/assets/Birthday/birthdayBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.nameContainer p {
  margin-bottom: 0;
  font-size: 20px;
  font-family: "REDENSEK", sans-serif;
}

.nameContainer input {
  color: white;
  width: 50%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #000000;
  outline: none;
  background: rgb(220, 220, 220, 0.3);
  border-radius: 4px;
  font-size: 20px;
  font-family: "REDENSEK", sans-serif;
}

.nameContainer input:focus {
  outline: none;
}

.confirmBtn {
  background-image: url("/public/assets/standard_button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 12.5px 65px;
  border: none;
  margin-top: 20px;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  font-family: "REDENSEK", sans-serif;
}

