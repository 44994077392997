.datePickerContainer {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  font-size: 16px;
  font-family: "REDENSEK", sans-serif;
}

.datePicker {
  display: flex;
}

.confirmBtn {
  background-image: url("/public/assets/standard_button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 12.5px 65px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  font-family: "REDENSEK", sans-serif;
}
