.mainGameArea {
  height: 50%;
  color: black;
}

.bgImage {
  background-image: url("/public/assets/click_scene_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickAreaScreen {
  position: relative;
  background-image: url("/public/assets/click_scene_fg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 85%;
  height: 73%;
  cursor: none;
}

.clickArea {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 70px;
  height: 70px;
  border-radius: 25%;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  /* opacity: 0; */
  cursor: none;
}

.a1 {
  background-image: url("/public/assets/enemy/A 1.png");
  opacity: 1;
}

.b1 {
  background-image: url("/public/assets/enemy/B 1.png");
  opacity: 1;
}

.c1 {
  background-image: url("/public/assets/enemy/C 1.png");
  opacity: 1;
}

.d1 {
  background-image: url("/public/assets/enemy/D 1.png");
  opacity: 1;
}

.e1 {
  background-image: url("/public/assets/enemy/E 1.png");
  opacity: 1;
}

.a1-dieing {
  background-image: url("/public/assets/enemy/A 2.png");
  /* opacity: 0; */
}

.b1-dieing {
  background-image: url("/public/assets/enemy/B 2.png");
  /* opacity: 0; */
}

.c1-dieing {
  background-image: url("/public/assets/enemy/C 2.png");
  /* opacity: 0; */
}

.d1-dieing {
  background-image: url("/public/assets/enemy/D 2.png");
  /* opacity: 0; */
}

.e1-dieing {
  background-image: url("/public/assets/enemy/E 2.png");
  /* opacity: 0; */
}

@keyframes dieing-blink {
  0% {
    filter: grayscale(0%);
    opacity: 1;

  }
  20% {
    filter: grayscale(10%);
    opacity: .5;

  }
  40% {
    filter: grayscale(20%);
    opacity: 1;

  }
  60% {
    filter: grayscale(30%);
    opacity: .5;

  }
  80% {
    filter: grayscale(40%);
    opacity: 1;

  }
  100% {
    filter: grayscale(60%);
    opacity: 0;
  }
}

.dieing {
  animation: dieing-blink 0.3s steps(5) forwards;
}

.customCursor {
  position: absolute;
  width: 75px; /* Adjust size as needed */
  height: 75px; /* Adjust size as needed */
  background-image: url("/public/assets/images.png");
  background-size: cover;
  pointer-events: none;
  /* transform: translate(-50%, -50%);  */
  mix-blend-mode: lighten;
  z-index: 9;
  opacity: 0;
}

.customCursor.visible {
  opacity: 1;
}

.customCursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  pointer-events: none;
  transform: scale(2);
}

.customCursor.shrink {
  animation: shrink-grow 0.2s ease;
}

@keyframes shrink-grow {
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2);
  }
}
