.shopContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-family: "Press Start 2P", cursive;
  background-image: url("/public/assets/Shop_bg_new.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.itemSlider {
  display: flex;
  justify-content: center;
  /* overflow-x: hidden; */
  width: 90%;
  height: 100%;
  position: relative;
}

.item {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  min-width: 80px;
  transition: transform 0.3s;
}

.disabled {
  filter: grayscale(1);
}

.readyToBuy:hover:enabled {
  transform: scale(1.3);
  color: #000;
}

.selected {
  transform: scale(1.3);
  color: #000;
}

.boughtTag {
  position: absolute;
  margin: 0;
  transform: rotate(-40deg);
  font-family: "REDENSEK", sans-serif;
  font-size: 28px;
  color: #fff;
  transform: translateX(15%) translateY(100%) rotate(-15deg);
  text-shadow:
    2px 0 #009664,
    -2px 0 #009664,
    0 2px #009664,
    0 -2px #009664,
    1px 1px #009664,
    -1px -1px #009664,
    1px -1px #009664,
    -1px 1px #009664;
}

.bottomContainer {
  position: absolute;
  bottom: 24%;
}

.cost {
  font-size: 14px;
  margin-bottom: 10px;
}

.buyButton {
  background-image: url("/public/assets/standard_button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background-color: #fff; */
  padding: 12.5px 65px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  /* transition: background-color 0.3s; */
}

/* .buyButton:disabled {
  filter: contrast(0.5);
} */

/* .buyButton:hover:enabled {
  background-color: #ccc;
} */

.splideArrows {
  position: absolute;
  display: flex;
  width: 20%;
  right: 10%;
  bottom: 20%;
}

.arrowImg img {
  width: 70%;
  cursor: pointer;
}

.sliderButton {
  background-color: #555;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
  transition: background-color 0.3s;
  z-index: 0;
}

.sliderButton:hover {
  background-color: #777;
}

.splide--splide {
  width: 100%;
}

.splide__list {
  display: flex;
}

.splide_track {
  /* margin-top: 1%; */
  /* background-color: yellow; */
}

.deco {
  position: relative;
  height: max-content;
  margin-top: 2.5%;
  margin-left: 4.5%;
}

.shopText {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 8%;
  top: 16.5%;
  display: block;
  font-family: "REDENSEK", sans-serif;
  color: #8cffd9;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
}

/* .deco::before {
  content: "SHOP";
  width: 100px;
  height: 100px;
  position: absolute;
  left: -10%;
  top: 17.5%;
  display: block;
  font-family: "REDENSEK", sans-serif;
  color: #8cffd9;
  font-size: 24px;
  font-weight: bold;
} */

.deco::after {
  content: "";
  width: 5%;
  height: 40%;
  position: absolute;
  left: -5%;
  top: 30%;
  display: block;
  /* background-color: red; */
  background-image: url("/public/assets/shop_decorative.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: none;
}

.tooltipContent p {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}
