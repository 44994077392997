.shopContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-family: "Press Start 2P", cursive;
  background-image: url("/public/assets/Data_log/Library/library6.Png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.itemSlider {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  left: 3%;
}

.tooltipContent p {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}

.item {
  display: flex;
  justify-content: center;
  padding: 2.5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  width: 100%;
}

.itemContent {
  position: relative;
  width: 100%;
  max-width: 45px;
}

.item img {
  max-width: 100%;
}

.progressBar {
  height: 2px;
  background-color: red;
}

.item:disabled {
  filter: grayscale(1);
}

.item:hover:enabled {
  background-color: #00ffff;
  color: #000;
}

.newTag {
  position: absolute;
  margin: 0;
  z-index: 999;
  transform: rotate(-40deg);
  font-family: "REDENSEK", sans-serif;
  font-size: 24px;
  color: #fff;
  transform: translateX(-50%) rotate(-40deg);
  text-shadow: 2px 0 #d93d3e, -2px 0 #d93d3e, 0 2px #d93d3e, 0 -2px #d93d3e,
    1px 1px #d93d3e, -1px -1px #d93d3e, 1px -1px #d93d3e, -1px 1px #d93d3e;
}

.viewed {
  position: absolute;
  margin: 0;
  top: 10%;
  transform: rotate(-90deg);
  font-family: "REDENSEK", sans-serif;
  font-size: 24px;
  color: #fff;
  transform: translateX(-20%) rotate(-40deg);
  text-shadow: 2px 0 #355E3B, -2px 0 #355E3B, 0 2px #355E3B, 0 -2px #355E3B,
    1px 1px #355E3B, -1px -1px #355E3B, 1px -1px #355E3B, -1px 1px #355E3B;
}

.splideArrows {
  position: absolute;
  display: flex;
  width: 20%;
  right: 10%;
  bottom: 15%;
}

.arrowImg img {
  width: 70%;
  cursor: pointer;
}

.sliderButton {
  background-color: #555;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.sliderButton:hover {
  background-color: #777;
}

.splide--splide {
  width: 100%;
}

.splide__list {
  display: flex;
}

.autoRunStoryContainer {
  position: absolute;
  right: 8%;
  top: 12%;
  width: fit-content;
}
