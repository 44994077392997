.selectedNodes {
  display: flex;
  /* width: 80.65%; */
  /* max-width: 80.65%; */
  height: 25%;
  align-items: center;
  background-image: url("/public/assets/node\ bar.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 25px;
  margin-top: -2%;
  overflow: hidden;
}

.selectedNode {
  padding-top: 1.35%;
  display: flex;
  align-items: center;
  position: relative;
  /* padding: 0%; */
}

.selectedNode img {
  /* width: 50%; */
  width: 80%;
}

.sup{
  position: absolute;
  left: 70%;
  top: 30%;
  font-size: 18px;
}