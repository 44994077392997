.activeBuffs {
  padding: 14px 17px;
  background-image: url("/public/assets/active\ buff\ bar.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  height: 20%;
}

.birthdayAvailedWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.birthdayAvailedWrapper img {
  width: 75px;
}

.sup {
  position: absolute;
  left: 70%;
  top: 30%;
  font-size: 18px;
}

.activeBuffsWrapper {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  align-items: center;
  margin: 0;
  justify-content: center;
}

.buff {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 5px;
  font-size: 9px;
  opacity: 0.5;
}

.img {
  width: 100%;
  cursor: pointer;
}

.buff:disabled {
  filter: contrast(0.5);
}

.active {
  opacity: 1;
}

.tooltipContent p {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}
