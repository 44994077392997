@font-face {
  font-family: "REDENSEK";
  src: url("https://nalikes-dump.s3.us-west-2.amazonaws.com/fonts/REDENSEK.TTF")
    format("truetype"); /* Locate the .ttf file within your directory*/
  font-weight: normal;
  font-style: normal;
}

.nodes_container {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 60%;
  position: relative;
  /* margin-top: ; */
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 6px; /* Rounded corners for the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color when hovering over the scrollbar handle */
}

.node {
  width: 30vw;
  height: 75px;
  /* height: 100%; */
  position: relative;
  background-image: url("/public/assets/Node/Node_bg.png");
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
  /* filter: none; */
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

.imageContainer {
  /* position: absolute; */
  left: 0;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background-image: url("/public/assets/Node/Node\ bg\ left.png");
  padding: 0;
  margin: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.imageContainer img {
  width: 65%;
}

.node:disabled {
  filter: contrast(0.5);
}

.node.locked {
  filter: contrast(1);
  background-image: url("/public/assets/Node/locked\ node.png");
  display: flex;
  justify-content: center;
  align-items: center;
}

.node.locked::after {
  content: "Locked";
  font-family: "REDENSEK", sans-serif;
  font-size: 36px;
  position: relative;
  font-weight: bolder;
  background: -webkit-linear-gradient(
    #f24aff,
    #f462ff,
    #f462ff,
    #f462ff,
    #bd2ec8,
    #bd2ec8,
    #bd2ec8,
    #bd2ec8,
    #bd2ec8
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 16px;
}

.node.locked > * {
  display: none;
}

.idContainer {
  width: 100%;
  text-align: center;
  font-family: "REDENSEK", sans-serif;
  background-image: url("/public/assets/Node/Node\ bg\ right.png");
  padding: 0;
  margin: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.idContainer h1 {
  text-transform: uppercase;
}

.tooltipContent p {
  margin: 0;
  font-size: 15px;
}
