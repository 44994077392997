.rollerText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.staggerChangeTop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
}
.staggerChangeBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  /* position: absolute; */
}
.offsetTop {
  transform: translateY(-48px);
}
.offsetBottom {
  transform: translateY(48px);
}

/* Roller Select */

.rollerSelectMain {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.buttonContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

.buttonContainer button {
  /* width: 20px;
  height: 20px; */
  margin: 0;
  padding: 0;
}

.buttonContainer button img {
  /* width: 20px;
  height: 20px; */
  margin: 0;
  padding: 0;
}
