.modalMain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: -1;
}

.open {
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-50px);
  transition: transform 0.3s ease;
  width: 60%;
  height: 50%;
}

.modalContent.open {
  transform: translateY(0);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
