/* src/Tooltip.css */
.tooltipContainer {
  position: relative;
  width: max-content;
}

.tooltipBox {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background-color: #2c2c2c;
  border: 5px solid #426b81;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  font-family: "REDENSEK", sans-serif;
}

.tooltipContainer:hover .tooltipBox {
  opacity: 1;
  visibility: visible;
}
