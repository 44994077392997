.preloadContainer {
  background-color: black;
  height: 100vh;
  width: 100vw;
}

.preloadContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.preloadContent h1 {
  font-family: "REDENSEK", sans-serif;
  font-size: 36px;
  font-weight: 400;
  color: white;
}
