@font-face {
  font-family: "REDENSEK";
  src: url("https://nalikes-dump.s3.us-west-2.amazonaws.com/fonts/REDENSEK.TTF") format("truetype"); /* Locate the .ttf file within your directory*/
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "REDENSEK", sans-serif;
  background-color: #2c2c2c;
  overflow: hidden;
}

.App {
  text-align: center;
  color: white;
}

.main {
  display: flex;
  height: 100vh;
}

h1 {
  padding: 0;
  margin: 0;
}

button {
  background: none;
  outline: none;
  border: none;
}

.splide__track {
  padding-bottom: 10%;
  margin-top: 10%;
}

.react-calendar__tile {
  color: inherit !important;
}

.react-calendar__tile--active {
  color: white !important;
}

/* .splide__track {
  padding-bottom: 0% !important;
  margin-top: 0% !important;
} */

/* .splide__list::after {
  content: "";
  width: 5%;
  height: 80%;
  position: absolute;
  left: -10%;
  top: 10%;
  z-index: 999;
  display: block;
  background-image: url("/public/assets/shop_decorative.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: none;
} */
