.languageContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
  font-family: "Press Start 2P", cursive;
  color: #ffffff;
  background-image: url("/public/assets/Birthday/birthdayBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: "REDENSEK", sans-serif;
}

.confirmBtn {
  background-image: url("/public/assets/standard_button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 12.5px 65px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  font-family: "REDENSEK", sans-serif;
}

.languageOptions {
  display: flex;
  margin-bottom: 30px;
}

.languageContainer .changeLanguageTitle {
  font-size: 28px;
  font-weight: 500;
}

.languageItem {
  border: 3px solid #b3b3b3;
  background-color: #261f48;
  margin-right: 20px;
  padding: 5px 40px;
  text-transform: capitalize;
}

.languageItem:hover {
  background-color: #5783c3;
}
