.characterContainer {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    font-family: "Press Start 2P", cursive;
    color: #ffffff;
    background-image: url("/public/assets/Birthday/birthdayBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}

.characterContainer p {
    margin-top: 50px;
    margin-bottom: 0;
    font-size: 28px;
    font-family: "REDENSEK", sans-serif;
    position: absolute;
    top: 10%;
}

.chooseNow{
    position: absolute;
    /* top: 30%; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chooseNow img{
    width: 25%;
    transform-origin: bottom;
    transform: scale(.9);
    transition: all 250ms ease;
    border: .25px solid white;
    border-radius: 100%;
}

.chooseNow img:hover{
    transform: scale(1);
    cursor: pointer;
}