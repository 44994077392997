.gearsContainer {
  width: 7.5%;
  min-width: 7.5%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAKgCAYAAABk73ilAAAAAXNSR0IArs4c6QAABRdJREFUeJzt1stJA0AUQNEoWYuQfToQLCGQFrQBK7Aidy78tCBYi/uAHWgDme1cyJyznNk8uDx4mw0AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADDb1dvr+189xMqu6wFWJ0BMgJgAMQFiAsQEiAkQEyAmQEyA2Hb08fX9M3OOi3c87M++24CYADEBYgLEBIgNr6Dd7nbmHMuyATEBYgLEBIgJEBteQR+fLzPnuHj3d89n321ATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyAmAAxAWICxASICRDbjj4eH55mzrEsGxATICZATICYALHhFXQ6/c6cYwE3Z19tQEyAmAAxAWICxIZX0PGwnznHsmxATICYADEBYgLEBIgJEBMgJkBMgJgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAcv4BumcRn6YBNaAAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: 100% 128%;
  background-position: 0%;
  position: relative;
}

.gearsWrapper{
  width: 100%;
  margin-top: 25%;
}

.gear {
  position: relative;
  width: 100%;
  /* height: 100%; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* padding: 10px; */
  margin-top: 7.5%;

}

.gear img {
  position: relative;
  /* left: -4px; */
  /* top: 20px; */
  width: 100%;
  cursor: pointer;
  /* margin-bottom: 7.5px; */
}

.gear:disabled {
  filter: contrast(0.5);
}