.birthdayMessageContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: "Press Start 2P", cursive;
  color: #ffffff;
  background-image: url("/public/assets/Birthday/birthdayBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.message{
  width: 100% !important;
}