.dpsWrapper {
  /* background-color: #ff6600; */
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/public/assets/dps.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  /* padding: 10px; */
}

.damageDealt {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
}

.damageDealt span {
  font-weight: bold;
}

.dps {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.iconWrapper {
  position: absolute;
  right: 11%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70%;
  top: 18%;
  /* background: rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(4px); */
}

.iconWrapper img {
  width: 100%;
  max-width: 30px;
}

.iconWrapper button {
  cursor: pointer;
}

.language {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/public/assets/Basic icon/language_default.png");
}

.language:hover {
  background-image: url("/public/assets/Basic icon/language_hover.png");
}

.mute {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/public/assets/Basic icon/mute_default.png");
}

.mute:hover {
  background-image: url("/public/assets/Basic icon/mute_hover.png");
}

.mute[data-muted="true"] {
  background-image: url("/public/assets/Basic icon/muted_default.png");
}

.mute[data-muted="true"]:hover {
  background-image: url("/public/assets/Basic icon/muted_hover.png");
}

.shop {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/public/assets/Basic icon/shop.png");
}

.shop:hover {
  background-image: url("/public/assets/Basic icon/shop_hover.png");
}

.language {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/public/assets/Basic icon/language_default.png");
}

.language:hover {
  background-image: url("/public/assets/Basic icon/language_hover.png");
}

.audio {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/public/assets/Basic icon/muted_default.png");
}

.audio:hover {
  background-image: url("/public/assets/Basic icon/muted_hover.png");
}

.audioMuted {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/public/assets/Basic icon/mute_default.png");
}

.audioMuted:hover {
  background-image: url("/public/assets/Basic icon/mute_hover.png");
}
